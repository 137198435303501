<template>
  <DashboardHeader title="Shiriki Add-On" icon="fa-solid fa-puzzle-piece" iconType="class">
    <template v-slot:aside>
      <div class="bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center">
        <span class="font-poppins font-medium md:text-[1rem] text-[0.60rem] text-[#959CBD]">Today:
          <span class="text-[#4E8D6D] font-semibold">{{ today }}</span></span>
        <img src="../../assets/today.png" alt="africa" class="w-[1.099rem] h-[1.062rem] rounded ml-1" />
      </div>
      <button type="button" class="bg-[#4E8D6D] md:px-6 px-4 py-2 rounded-[0.327rem] flex items-center"
        @click="showUploadMenuModal = true" v-if="activeTab === 'food-drink-menu' &&
          loggedInUser.sme.has_food_drink_menu_feature_enabled
        ">
        <span class="font-poppins font-semibold md:text-[1rem] text-[0.8rem] text-[#fff] flex">
          <img src="../../assets/plus-icon.png" class="md:w-[1rem] w-[0.6rem] md:h-[1rem] h-[0.6rem] mt-1 mr-2"
            alt="" />
          Add Menu</span>
      </button>
    </template>
  </DashboardHeader>

  <suspended-page-message v-if="!loggedInUser.sme?.is_active"></suspended-page-message>

  <div v-else class="w-full my-3">
    <div v-if="loggedInUser.sme?.has_not_paid_for_one_billing_five_days_ago">
      <payment-required-message />
    </div>

    <activate-subscription-message v-else-if="!loggedInUser.sme.subscription_plan" />

    <div v-else>
      <div class="w-full my-3">
        <div v-if="loggedInUser.sme?.has_not_paid_for_one_billing_five_days_ago">
          <payment-required-message />
        </div>
        <div v-else
          class="bg-white w-full min-h-[25rem] rounded-[0.938rem] py-3 md:px-6 px-3 shadow overflow-auto relative">
          <div class="tabs-block" id="tabs-block">
            <div class="flex justify-between">
              <ul class="flex flex-wrap text-sm font-medium text-center mb-2" id="myTab"
                data-tabs-toggle="#myTabContent" role="tablist">
                <li class="mr-2">
                  <button class="inline-block p-4 py-2 rounded-md w-full" id="routers" data-tabs-target="#routers"
                    type="button" role="tab" aria-controls="routers" aria-selected="false"
                    @click="setActiveTab('sms-package')" v-bind:class="[
                      activeTab === 'sms-package'
                        ? 'bg-light text-[#4e8d6e]'
                        : 'bg-[transparent]',
                    ]">
                    SMS Package
                  </button>
                </li>
                <li class="mr-2">
                  <button class="inline-block p-4 py-2 rounded-md w-full" id="food-drink-menu"
                    data-tabs-target="#food-drink-menu" type="button" role="tab" aria-controls="food-drink-menu"
                    aria-selected="false" @click="setActiveTab('food-drink-menu')" v-bind:class="[
                      activeTab === 'food-drink-menu'
                        ? 'bg-light text-[#4e8d6e]'
                        : 'bg-[transparent]',
                    ]">
                    Food & Drinks Menu
                  </button>
                </li>
                <li class="mr-2">
                  <button class="inline-block p-4 py-2 rounded-md w-full" id="food-drink-menu"
                    data-tabs-target="#food-drink-menu" type="button" role="tab" aria-controls="food-drink-menu"
                    aria-selected="false" @click="setActiveTab('feedback')" v-bind:class="[
                      activeTab === 'feedback'
                        ? 'bg-light text-[#4e8d6e]'
                        : 'bg-[transparent]',
                    ]">
                    Feedback
                  </button>
                </li>
                <li class="mr-2">
                  <button class="inline-block p-4 py-2 rounded-md w-full" id="food-drink-menu"
                    data-tabs-target="#food-drink-menu" type="button" role="tab" aria-controls="food-drink-menu"
                    aria-selected="false" @click="setActiveTab('autoconnect')" v-bind:class="[
                      activeTab === 'autoconnect'
                        ? 'bg-light text-[#4e8d6e]'
                        : 'bg-[transparent]',
                    ]">
                    Auto-Connect
                  </button>
                </li>
                <li class="mr-2">
                  <button class="inline-block p-4 py-2 rounded-md w-full" id="token-purchase"
                    data-tabs-target="#token-purchase" type="button" role="tab" aria-controls="token-purchase"
                    aria-selected="false" @click="setActiveTab('token-purchase')" v-bind:class="[
                      activeTab === 'token-purchase'
                        ? 'bg-light text-[#4e8d6e]'
                        : 'bg-[transparent]',
                    ]">
                    Token Purchase
                  </button>
                </li>
                <li class="mr-2">
                  <button class="inline-block p-4 py-2 rounded-md w-full" id="food-drink-menu"
                    data-tabs-target="#food-drink-menu" type="button" role="tab" aria-controls="food-drink-menu"
                    aria-selected="false" @click="setActiveTab('table-reservation')" v-bind:class="[
                      activeTab === 'table-reservation'
                        ? 'bg-light text-[#4e8d6e]'
                        : 'bg-[transparent]',
                    ]">
                    Table Reservation
                  </button>
                </li>
              </ul>

              <!-- DEACTIVATE ADDON -->

              <!-- SMS -->
              <div v-if="activeTab === 'sms-package' &&
                loggedInUser.sme.has_voucher_sms_feature_enabled && !loggedInUser.sme?.has_not_paid_for_one_sms_billing
              ">
                <div v-if="!isPendingSMSAddOnCancellation()">
                  <button
                    class="w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border"
                    type="button" @click="deactivateAddOn('sms-addon')" v-if="!deactivating">
                    Deactivate SMS Add-On
                  </button>
                  <button v-else
                    class="w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border"
                    type="button" disabled>
                    Deactivating...
                  </button>
                </div>
              </div>

              <!-- FOOD & DRINKS MENU -->
              <div v-if="activeTab === 'food-drink-menu' &&
                loggedInUser.sme.has_food_drink_menu_feature_enabled
              ">
                <div v-if="!isPendingAddOnCancellation('menu')">
                  <button
                    class="w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border"
                    type="button" @click="deactivateAddOn('menu-addon')" v-if="!deactivating">
                    Deactivate Menu Add-On
                  </button>
                  <button v-else
                    class="w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border"
                    type="button" disabled>
                    Deactivating...
                  </button>
                </div>
              </div>

              <!-- FEEDBACK -->
              <div v-if="activeTab === 'feedback' &&
                loggedInUser.sme.has_rating_feature_enabled
              ">
                <div v-if="!isPendingAddOnCancellation('feedback')">
                  <button
                    class="w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border"
                    type="button" @click="deactivateAddOn('feedback-addon')" v-if="!deactivating">
                    Deactivate Feedback Add-On
                  </button>
                  <button v-else
                    class="w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border"
                    type="button" disabled>
                    Deactivating...
                  </button>
                </div>
              </div>

              <!-- AUTO-CONNECT -->
              <div v-if="activeTab === 'autoconnect' &&
                loggedInUser.sme.has_autoconnect_addon_enabled
              ">
                <button
                  class="w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border"
                  type="button" @click="deactivateAddOn('autoconnect-addon')" v-if="!deactivating">
                  Deactivate Auto-Connect Add-On
                </button>
                <button v-else
                  class="w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border"
                  type="button" disabled>
                  Deactivating...
                </button>
              </div>

              <!-- TOKEN PURCHASE -->
              <div v-if="activeTab === 'token-purchase' &&
                loggedInUser.sme.has_token_purchase_addon_enabled
              ">
                <button
                  class="w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border"
                  type="button" @click="deactivateAddOn('token-purchase-addon')" v-if="!deactivating">
                  Deactivate Token Purchase Add-On
                </button>
                <button v-else
                  class="w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border"
                  type="button" disabled>
                  Deactivating...
                </button>
              </div>

              <!-- TABLE RESERVATION -->
              <div v-if="activeTab === 'table-reservation' &&
                loggedInUser.sme.has_table_reservation_addon_enabled
              ">
                <div v-if="!isPendingAddOnCancellation('table')">
                  <button
                    class="w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border"
                    type="button" @click="deactivateAddOn('table-reservation-addon')" v-if="!deactivating">
                    Deactivate Table Reservation Add-On
                  </button>
                  <button v-else
                    class="w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border"
                    type="button" disabled>
                    Deactivating...
                  </button>
                </div>
              </div>
            </div>

            <div id="TabContent">
              <!-- SMS PACKAGE TAB CONTENT -->
              <div v-if="activeTab === 'sms-package'">
                <p v-if="isPendingSMSAddOnCancellation()" class="text-[#f24236] text-xs mb-3 w-1/2">
                  You've recently requested to deactivate your SMS Add-On. Just a reminder that this change will take
                  effect at the end of your current billing cycle. You'll continue to have access to the add-on until
                  then.
                </p>
                <div class="w-full flex">
                  <div class="w-1/2">
                    <UpgradeSMSPackage :defaultSMSPackages="smsPackages" :processingSMSPackage="processingSMSPackage"
                      :pendingSMSPackages="pendingSMSPackages" :show-cancellation-dialog="showCancellationDialog"
                      :loading-cancellation="loadingCancellation" @activate-sms-package="displaySMSPaymentModal"
                      @change-sms-package="changeSMSPackage" @toggle-cancellation-dialog="toggleCancellationDialog"
                      @cancel-sms-package="requestForAddOnDeactivation"></UpgradeSMSPackage>
                  </div>

                  <!-- SMS DURATION  -->
                  <div
                    v-if="loggedInUser.sme.has_voucher_sms_feature_enabled && !loggedInUser.sme?.has_not_paid_for_one_sms_billing"
                    class="bg-white w-1/2 min-h-[25rem] rounded-[0.938rem] p-6 shadow overflow-auto relative">
                    <h3 class="font-bold text-lg mb-2 text-primary">
                      SMS TOKEN DURATION
                    </h3>
                    <p class="mb-4">
                      The voucher SMS feature lets users request a WiFi token via
                      SMS, instantly connecting them without needing manual token
                      distribution. It allows users to request internet access
                      tokens via SMS on the box's captive portal, eliminating the
                      need for admins to create tokens manually in the dashboard.
                    </p>
                    <div class="flex justify-start">
                      <label class="block text-sm font-medium text-slate-700 mb-3 mr-3 mt-3">
                        Token Duration:
                      </label>
                      <select id="duration" type="duration"
                        class="text-[14px] h-[2.688rem] border-[0.063rem] rounded-[0.3rem] px-3 py-2 border-slate-300 placeholder-slate-400 focus:outline-none block focus:ring-1"
                        v-model="duration">
                        <option value="Select Default Duration" disabled selected>
                          Select Default Duration
                        </option>
                        <option value="1_hour">1 Hour</option>
                        <option value="2_hours">2 Hours</option>
                        <option value="5_hours">5 Hours</option>
                        <option value="10_hours">10 Hours</option>
                        <option value="24_hours">24 Hours</option>
                        <option value="custom">Custom Minutes</option>
                      </select>
                      <input v-if="duration === 'custom'"
                        class="ml-4 text-[14px] h-[2.688rem] border-[0.063rem] rounded-[0.3rem] px-3 py-2 border-slate-300 placeholder-slate-400 focus:outline-none block focus:ring-1"
                        type="number" name="custom_duration" v-model="customDuration" placeholder="0" />
                    </div>

                    <div v-if="loggedInUser.sme.has_voucher_sms_feature_enabled">
                      <button v-if="!loadingVoucherDurationPreference" @click="setVoucherPreference()" type="button"
                        class="font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2">
                        Save
                      </button>
                      <button v-else type="button"
                        class="font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2">
                        Processing...
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- FOOD & MENU TAB CONTENT -->
              <div v-if="activeTab === 'food-drink-menu'" class="w-full">
                <div v-if="loggedInUser.sme.has_food_drink_menu_feature_enabled">
                  <p v-if="isPendingAddOnCancellation('menu')" class="text-[#f24236] text-xs mb-3 w-1/2">
                    You've recently requested to deactivate the Menu Add-On. Just a reminder that this change will take
                    effect at the end of your current billing cycle. You'll continue to have access to the add-on until
                    then.
                  </p>
                  <DashboardTableTitle v-if="propLoaded" :perPageItems="perSize" :pageNumber="page"
                    :ApiData="foodDrinkMenus" @handleNext="handlleNext" @handlePrev="handlePrev">
                    <template v-slot:section>
                      <div>
                        <div class="mt-5 flex items-center">
                          <span class="text-[#B5B5C3] font-poppins font-medium text-[0.673rem]">Rows per
                            page</span><span
                            class="bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]">
                            <select v-model="selectedOption" @change="handleChange">
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="30">30</option>
                              <option value="40">40</option>
                              <option value="50">50</option>
                            </select></span>
                        </div>
                      </div>
                    </template>
                  </DashboardTableTitle>

                  <SmeTable :tableHeaderData="foodDrinkMenuTableHeaders">
                    <template v-slot:tableBodyData>
                      <template v-if="foodDrinkMenus.length">
                        <div v-for="menu in foodDrinkMenus" :key="menu.id"
                          class="table-layout-tr py-2 my-3 rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]">
                          <td>
                            <span class="text-[#464E5F] capitalize">
                              {{ menu.menu_type }}
                            </span>
                          </td>
                          <td>
                            <span class="text-[#464E5F]">{{
                              menu.realm.name
                            }}</span>
                          </td>
                          <td>
                            <span class="text-[#464E5F]">{{
                              menu.original_file_name
                            }}</span>
                          </td>
                          <td>
                            <span class="text-[#464E5F]">
                              {{ createdAt(menu.created) }}
                            </span>
                          </td>
                          <td>
                            <div>
                              <span @click="downloadFile(menu.file)"
                                class="capitalize p-2 rounded bg-[#4e8d6e] text-white mb-0.5 inline-block text-center whitespace-nowrap cursor-pointer">Download<i
                                  class="ml-1.5 fa-solid fa-download"></i></span>
                            </div>
                          </td>
                          <td>
                            <div class="flex items-center justify-end">
                              <div>
                                <span v-if="!deleting ||
                                  currentDeletionFileID !== menu.id
                                " @click="deleteMenu(menu.id)"
                                  class="capitalize p-2 rounded bg-[#f24236] text-white mb-0.5 inline-block text-center cursor-pointer">Delete</span>
                                <ScaleLoader v-if="deleting &&
                                  currentDeletionFileID === menu.id
                                " color="#d7191c"></ScaleLoader>
                              </div>
                            </div>
                          </td>
                        </div>
                      </template>
                      <div v-else class="w-full text-center text-[12px] text-[#afafaf] mt-4">
                        <b>No Food & Drinks Menus Found. Click on the Add Menu
                          button in the top-right corner to one</b>
                      </div>
                    </template>
                  </SmeTable>
                </div>

                <div v-else>
                  <activate-add-on noActiveAddOnTitle="Food & Drinks Menu Add-On Inactive"
                    noActiveAddOnMessage="The Food & Drinks Menu Add-On will help you add Food & Drinks menus  to your captive portal so that users can view them"
                    :price="2500" :processingAddOnActivation="processingAddOnActivation"
                    @activate-add-on="activateFoodMenuAddOn"></activate-add-on>
                </div>

                <div v-if="loading" class="spinner-container">
                  <div class="spinner"></div>
                </div>
              </div>

              <!-- FEEDBACK TAB CONTENT -->
              <div v-if="activeTab === 'feedback'">
                <div v-if="loggedInUser.sme.has_rating_feature_enabled">
                  <p v-if="isPendingAddOnCancellation('feedback')" class="text-[#f24236] text-xs mb-3 w-1/2">
                    You've recently requested to deactivate the Feedback Add-On. Just a reminder that this change will
                    take
                    effect at the end of your current billing cycle. You'll continue to have access to the add-on until
                    then.
                  </p>
                  <DashboardTableTitle v-if="propLoaded" :perPageItems="perSize" :pageNumber="page"
                    :ApiData="feedbackList" @handleNext="handlleNext" @handlePrev="handlePrev">
                  </DashboardTableTitle>

                  <FeedbackDashboard @filterData="fetchFeedback" />

                  <SmeTable :tableHeaderData="feedbackTableHeaders">
                    <template v-slot:tableBodyData>
                      <template v-if="paginatedTableData.length">
                        <div v-for="feedback in paginatedTableData" :key="feedback.id"
                          class="table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]">
                          <td>
                            <div class="flex items-center">
                              <div class="flex flex-col ml-3">
                                <span class="capitalize text-[#464E5F] mb-0.5">{{ feedback.rating }}</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span class="text-[#464E5F]">{{
                              feedback.comment
                            }}</span>
                          </td>
                          <td>
                            <div class="flex flex-col">
                              <span class="capitalize text-[#464E5F] mb-0.5">{{
                                feedback.created
                              }}</span>
                            </div>
                          </td>
                        </div>
                      </template>
                      <div v-else class="w-full text-center text-[12px] text-[#afafaf] mt-4">
                        <b>No feedback found</b>
                      </div>
                    </template>
                  </SmeTable>
                  <div class="spinner-container">
                    <div v-if="loading" class="spinner"></div>
                  </div>
                </div>
                <div v-else>
                  <activate-add-on noActiveAddOnTitle="Feedback Add-On Inactive"
                    noActiveAddOnMessage="The Feedback Add-On will help you receive feedback and ratings from users connected to your captive portal"
                    :processingAddOnActivation="processingAddOnActivation" :price="2500"
                    @activate-add-on="activateFeedbackAddOn"></activate-add-on>
                </div>
              </div>

              <!-- AUTO-CONNECT TAB CONTENT -->
              <div v-if="activeTab === 'autoconnect'">
                <div v-if="loggedInUser.sme.has_autoconnect_addon_enabled">

                  <!-- AUTO-CONNECT CONTENT -->

                  <div class="bg-white w-1/2 min-h-[25rem] rounded-[0.938rem] p-6 shadow overflow-auto relative">
                    <h3 class="font-bold text-lg mb-2 text-primary">
                      AUTO-CONNECT TOKEN DURATION
                    </h3>
                    <p class="mb-4">
                      Our Auto-Connect Token Duration feature automatically generates a token when the user connects,
                      ensuring seamless internet access. Administrators can set up the default duration for user
                      internet
                      access, ensuring uninterrupted connectivity without the hassle of manual token creation.
                    </p>
                    <div class="flex justify-start">
                      <label class="block text-sm font-medium text-slate-700 mb-3 mr-3 mt-3">
                        Token Duration:
                      </label>
                      <select id="duration" type="duration"
                        class="text-[14px] h-[2.688rem] border-[0.063rem] rounded-[0.3rem] px-3 py-2 border-slate-300 placeholder-slate-400 focus:outline-none block focus:ring-1"
                        v-model="duration">
                        <option value="Select Default Duration" disabled selected>
                          Select Default Duration
                        </option>
                        <option value="1_hour">1 Hour</option>
                        <option value="2_hours">2 Hours</option>
                        <option value="5_hours">5 Hours</option>
                        <option value="10_hours">10 Hours</option>
                        <option value="24_hours">24 Hours</option>
                        <option value="custom">Custom Minutes</option>
                      </select>
                      <input v-if="duration === 'custom'"
                        class="ml-4 text-[14px] h-[2.688rem] border-[0.063rem] rounded-[0.3rem] px-3 py-2 border-slate-300 placeholder-slate-400 focus:outline-none block focus:ring-1"
                        type="number" name="custom_duration" v-model="customDuration" placeholder="0" />
                    </div>

                    <div class="flex items-center mt-4">
                      <label for="autoconnect-daily-usage"
                        class="block text-sm font-medium text-slate-700 mb-3 mr-3 mt-3">
                        Auto-Connect Daily Usage:
                      </label>
                      <input type="checkbox" id="autoconnect-daily-usage" v-model="autoconnectDailyUsage"
                        class="toggle-checkbox" />

                    </div>

                    <p class="mb-4">
                      Enabling this option will limit the daily usage of free internet access to the specified
                      token duration. Users will only be able to connect for the configured duration each day.
                    </p>

                    <button v-if="!loadingVoucherDurationPreference" @click="setVoucherPreference()" type="button"
                      class="font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2">
                      Save
                    </button>
                    <button v-else type="button"
                      class="font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2">
                      Processing...
                    </button>
                  </div>

                  <div class="spinner-container">
                    <div v-if="loading" class="spinner"></div>
                  </div>
                </div>
                <div v-else>
                  <activate-add-on noActiveAddOnTitle="Auto-Connect Add-On Inactive"
                    noActiveAddOnMessage="Introducing the Auto-Connect Add-On, designed to streamline Internet connectivity. Say goodbye to manual token entry as users effortlessly connect to the Internet with just a click."
                    :processingAddOnActivation="processingAddOnActivation" :price="0"
                    @activate-add-on="activateAutoConnectAddOn"></activate-add-on>
                </div>
              </div>

              <!-- TOKEN-PURCHASE TAB CONTENT -->
              <div v-if="activeTab === 'token-purchase'">
                <div v-if="loggedInUser.sme.has_token_purchase_addon_enabled" class="flex">

                  <!-- TOKEN-PURCHASE CONTENT -->

                  <div class="bg-white w-1/2 min-h-[25rem] rounded-[0.938rem] p-6 shadow overflow-auto relative">
                    <h3 class="font-bold text-lg mb-2 text-primary">
                      TOKEN PURCHASE ADD-ON
                    </h3>
                    <p class="mb-4">
                      Enhance your captive portal with our Token Purchase Addon, offering flexible Internet access
                      options
                      for users. This feature allows administrators to configure free access time and the option to
                      purchase tokens for extended Internet usage.
                    </p>

                    <p class="mb-4"><strong>Free Access Configuration: </strong>Administrators can set a default
                      duration
                      for free Internet access, ensuring users can connect seamlessly without initial payment. Whether
                      free access is permitted and its duration can be customized in the addon settings.</p>

                    <p class="mb-4"><strong>Daily Free Access Restriction: </strong>Free access is limited to a daily
                      basis. Users can enjoy a limited time of free Internet access each day, but once the free period
                      is
                      over, they will need to wait until the next day for another free session.</p>

                    <p class="mb-4"><strong>Token Purchase: </strong>Once the free access period expires, users will
                      need
                      to purchase tokens to continue using the Internet. Administrators can specify the token durations
                      and prices, providing clear options for users.</p>

                    <p class="mb-4"><strong>User Experience: </strong>When users connect to the WiFi network, the
                      captive
                      portal will open, displaying the free access details and token purchase options. This ensures a
                      smooth transition from free access to paid access, enhancing user satisfaction and monetizing
                      Internet access efficiently.</p>
                  </div>

                  <div class="bg-white w-1/2 ml-8 min-h-[25rem] rounded-[0.938rem] p-6 shadow overflow-auto relative">
                    <div v-if="editMode">
                      <h3 class="font-bold text-lg mb-4 text-primary">
                        TOKEN DURATIONS AND PRICES
                      </h3>
                      <div v-for="(item, index) in tokenPurchaseOptions" :key="index" class="flex items-center mb-4">
                        <label class="block text-sm font-medium text-slate-700 mb-2 mr-2">
                          Price (RWF):
                        </label>
                        <input type="text" v-model="item.price"
                          class="text-[14px] focus:outline-none h-[2.688rem] mr-4 rounded-[0.3rem] px-3 py-2 placeholder-slate-400 block"
                          readonly />
                        <label class="block text-sm font-medium text-slate-700 mt-2 mb-2 mr-2">
                          Duration (minutes):
                        </label>
                        <input type="number" v-model="item.duration_in_minutes" @input="updatedisplay_durations()"
                          class=" text-[14px] focus:outline-none focus:ring h-[2.688rem] rounded-[0.3rem] px-3 py-2
                          placeholder-slate-400 block" />
                      </div>
                      <button v-if="!loadingTokenPurchasePreference" @click="saveTokenPurchasePreference()"
                        type="button"
                        class="font-poppins text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2">
                        Save
                      </button>
                      <button v-else type="button"
                        class="font-poppins text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2">
                        Processing...
                      </button>
                    </div>
                    <div v-else>
                      <h3 class="font-bold text-lg mb-4 text-primary">
                        TOKEN DURATIONS AND PRICES
                      </h3>
                      <div v-for="(item, index) in tokenPurchaseOptions" :key="index" class="flex items-center mb-4">
                        <label class="block text-sm font-medium text-slate-700 mb-2 mr-2">
                          Price (RWF):
                        </label>
                        <input type="text" :value="item.price"
                          class="text-[14px] focus:outline-none h-[2.688rem] mr-4 rounded-[0.3rem] px-3 py-2 placeholder-slate-400 block"
                          readonly />
                        <label class="block text-sm font-medium text-slate-700 mt-2 mb-2 mr-2">
                          Duration:
                        </label>
                        <input type="text" :value="item.display_duration"
                          class="text-[14px] focus:outline-none h-[2.688rem] rounded-[0.3rem] px-3 py-2 placeholder-slate-400 block"
                          readonly />
                      </div>
                      <button @click="editMode = true" type="button"
                        class="font-poppins text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2">
                        Edit
                      </button>
                    </div>
                  </div>

                  <div class="spinner-container">
                    <div v-if="loading" class="spinner"></div>
                  </div>
                </div>
                <div v-else>
                  <activate-add-on noActiveAddOnTitle="Token Purchase Add-On Inactive"
                    noActiveAddOnMessage="Introducing the Token Purchase Addon, designed to simplify Internet access. Enjoy configurable free access and seamless token purchases for uninterrupted connectivity."
                    :processingAddOnActivation="processingAddOnActivation" :price="0"
                    @activate-add-on="activateTokenPurchaseAddOn"></activate-add-on>
                </div>
              </div>

              <!-- TABLE RESERVATION TAB CONTENT -->
              <div v-if="activeTab === 'table-reservation'">
                <div v-if="loggedInUser.sme.has_table_reservation_addon_enabled">
                  <p v-if="isPendingAddOnCancellation('table')" class="text-[#f24236] text-xs mb-3 w-1/2">
                    You've recently requested to deactivate the Table Reservation Add-On. Just a reminder that this
                    change
                    will
                    take
                    effect at the end of your current billing cycle. You'll continue to have access to the add-on until
                    then.
                  </p>

                  <div class="bg-white w-1/2 min-h-[25rem] rounded-[0.938rem] p-6 shadow overflow-auto relative">
                    <h3 class="font-bold text-lg mb-2 text-primary">
                      TABLE RESERVATION
                    </h3>
                    <p class="mb-4">
                      The addon is active. You can now access it when you connect to the box.
                    </p>
                  </div>

                  <div class="spinner-container">
                    <div v-if="loading" class="spinner"></div>
                  </div>
                </div>
                <div v-else>
                  <activate-add-on noActiveAddOnTitle="Table Reservation Add-On Inactive"
                    noActiveAddOnMessage="The Table Reservation Add-On enhances your box's capabilities by enabling seamless management of table reservations directly through the captive portal. Whether you run a restaurant, coffee shop, bar, or similar establishment, this feature empowers your waitstaff to efficiently handle table assignments and reservations."
                    :processingAddOnActivation="processingAddOnActivation" :price="20000"
                    @activate-add-on="activateTableAddOn"></activate-add-on>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODALS -->
  <upload-menu v-if="showUploadMenuModal" @close="closeModal()" />
  <sms-payment-modal :loading="processingAddOnActivation" :smsPackage="selectedSMSPackage"
    @pay-for-sms="activateSMSPackage" v-if="showSMSPaymentModal"
    @close="showSMSPaymentModal = !showSMSPaymentModal"></sms-payment-modal>

  <ared-eula-modal :loading="processingEuala" @accept="acceptEula" v-if="showEulaModal"
    @close="showEulaModal = !showEulaModal"></ared-eula-modal>

  <!-- MENU ADDON DEACTIVATION DIALOG -->
  <confirmation-dialog v-if="addOnToDeactivate === 'menu'" :show="showCancellationDialog"
    title="Menu Add-On Deactivation" message="
Disabling the Menu Add-On will result in the removal of the food and drinks menu from the captive portal. 
Currently, the menu addon enables your clients to conveniently access your menu directly from their phone or computer when they connect to your network. Please note that this change will take effect after the conclusion of your current billing cycle.
 Therefore, you will retain access until the end of the current billing period." actionLabel="Confirm"
    :loading="loadingCancellation" @cancel="toggleCancellationDialog(false)" @confirm="requestForAddOnDeactivation()" />

  <!-- FEEDBACK ADDON DEACTIVATION DIALOG -->
  <confirmation-dialog v-if="addOnToDeactivate === 'feedback'" :show="showCancellationDialog"
    title="Feedback Add-On Deactivation" message="
    Disabling the Feedback Add-On will result in the removal of the feedback page from your captive portal.
     Currently, the feedback addon empowers your clients to easily submit feedback and ratings directly from their phone or computer when they connect to your network.
      Please be aware that this change will come into effect at the conclusion of your current billing cycle. Therefore, you will continue to have access to the feedback feature until the end of the current billing period.
    " actionLabel="Confirm" :loading="loadingCancellation" @cancel="toggleCancellationDialog(false)"
    @confirm="requestForAddOnDeactivation()" />

  <!-- TABLE RESERVATION ADDON DEACTIVATION DIALOG -->
  <confirmation-dialog v-if="addOnToDeactivate === 'table'" :show="showCancellationDialog"
    title="Table Reservation Add-On Deactivation" message="
    Disabling the Table Reservation Add-On will result in the removal of the table reservation management functionality
     from your captive portal. Currently, the reservation addon enables your staff to efficiently handle table assignments
      and reservations directly through the captive portal. Please be aware that this change will come into effect at the conclusion of your current billing cycle. Therefore, you will continue to have access to the table reservation feature until the end of the current billing period.
    " actionLabel="Confirm" :loading="loadingCancellation" @cancel="toggleCancellationDialog(false)"
    @confirm="requestForAddOnDeactivation()" />
</template>

<script lang="ts">
import moment from 'moment';
import router from '@/router';
import { mapGetters, mapMutations } from 'vuex';
import { defineComponent, ref } from 'vue';

import instance from '../../axios-interceptor';
import { AddOnCancellation, Billing, SMSPackage, SmsPackageBilling } from '@/interfaces/billing';
import { Feedback, FoodDrinkMenu, TokenPurchaseOption } from '@/interfaces/router';
import SmeTable from '@/components/reusable/SmeTable.vue';
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';
import UploadMenu from '@/components/Common/UploadMenu.vue';
import ActivateAddOn from '@/components/Common/ActivateAddOn.vue';
import { PendingSMSPackageChange } from '@/interfaces/subscription';
import UpgradeSMSPackage from '@/components/Common/UpgradeSMSPackage.vue';
import DashboardHeader from '../../components/markup/DashboardHeader.vue';
import PaymentRequiredMessage from '@/components/Common/PaymentRequiredMessage.vue';
import FeedbackDashboard from '@/components/Shiriki/FeedbackDashboard.vue';
import {
  foodDrinkMenuTableHeaders as _foodDrinkMenuTableHeaders,
  feedbackTableHeaders as _feedbackTableHeaders,
} from '../../helpers/stub_data/dashboard_routers';
import SmsPaymentModal from '@/components/AddOn/SMSPayment.vue';
import AredEulaModal from '@/components/AddOn/EULA.vue';

export default defineComponent({
  name: 'ShirikiAddOn',
  components: {
    SmeTable,
    UploadMenu,
    ScaleLoader,
    ActivateAddOn,
    DashboardHeader,
    UpgradeSMSPackage,
    PaymentRequiredMessage,
    FeedbackDashboard,
    SmsPaymentModal,
    AredEulaModal,
  },
  setup() {
    const foodDrinkMenuTableHeaders = ref([..._foodDrinkMenuTableHeaders]);
    const feedbackTableHeaders = ref([..._feedbackTableHeaders]);

    const deleting = ref(false);
    const deactivating = ref(false);
    const currentDeletionFileID = ref(0);

    return {
      deleting,
      deactivating,
      feedbackTableHeaders,
      currentDeletionFileID,
      foodDrinkMenuTableHeaders,
    };
  },

  data() {
    return {
      activeTab: 'sms-package',
      loading: false,
      selectedOption: 10,
      apiData: [],
      propLoaded: false,
      page: 1,
      perSize: 10,
      showUploadMenuModal: false,
      smsPackages: [] as SMSPackage[],
      pendingSMSPackages: [] as PendingSMSPackageChange[],
      processingSMSPackage: false,
      foodDrinkMenus: [] as FoodDrinkMenu[],
      processingAddOnActivation: false,
      feedbackList: [] as Feedback[],
      processingRatingFeature: false,
      showModal: false,
      searchQuery: '',
      showCancellationDialog: false,
      loadingCancellation: false,
      loadingVoucherDurationPreference: false,
      duration: 'Select Default Duration',
      customDuration: null,
      showSMSPaymentModal: false,
      showEulaModal: false,
      selectedSMSPackage: {} as SMSPackage,
      subscriptionBillings: [] as Billing[],
      processingEuala: false,
      addOnToDeactivate: '',
      addOnToActivateAfterEula: '',
      autoconnectDailyUsage: false,
      loadingTokenPurchasePreference: false,
      editMode: false,
      tokenPurchaseOptions: [
        { price: '0 RWF', duration_in_minutes: 10, display_duration: '10 minutes (daily free)' },
        { price: '100 RWF', duration_in_minutes: 20, display_duration: '20 minutes' },
        { price: '300 RWF', duration_in_minutes: 60, display_duration: '1 hour' },
        { price: '500 RWF', duration_in_minutes: 180, display_duration: '3 hours' },
        { price: '1000 RWF', duration_in_minutes: 1440, display_duration: '1 day' }
      ] as TokenPurchaseOption[],
    };
  },

  computed: {
    ...mapGetters(['loggedInUser']),
    ...mapMutations(['setLoggedInUser']),
    today() {
      return moment(Date.now()).format('MMMM Do');
    },
    paginatedTableData(): Feedback[] {
      const startIndex = (this.page - 1) * this.perSize;
      const endIndex = startIndex + this.perSize;
      const filteredData = this.filterTableData();

      return filteredData.slice(startIndex, endIndex);
    },
  },

  methods: {
    setActiveTab(tab: string) {
      this.activeTab = tab;
      router.push({ query: { tab: tab } });
    },
    createdAt(date: Date) {
      if (date) {
        return moment(date).format('dddd, MMMM Do YYYY, h:mm a');
      }
    },
    handlePrev(data: any) {
      this.page--;
    },
    handlleNext(data: any) {
      this.page++;
    },
    getDate(date: Date | number) {
      return moment(date).calendar();
    },
    fetchSMSPackages() {
      instance
        .get('partner/sms-package/')
        .then((response: { data: any }) => {
          this.smsPackages = response.data;
          this.fetchPendingSMSPackages();
        })
        .catch((error: any) => {
          console.log(error);
        });
    },
    toggleCancellationDialog(value: boolean) {
      this.showCancellationDialog = value;

      if (!value) {
        this.addOnToDeactivate = '';
        this.deactivating = false;
      }
    },
    async fetchTokenPurchasePreferences() {
      try {
        this.loadingTokenPurchasePreference = true;
        const response = await instance.get('vouchers/purchase-preference/', {
          params: {
            sme: this.loggedInUser.sme.id
          }
        });

        if (response.data.length) {
          this.tokenPurchaseOptions = response.data.map((item: any) => ({
            price: item.price,
            duration_in_minutes: item.duration_in_minutes,
            display_duration: this.formatDuration(item.duration_in_minutes, item.price)
          }));
        }
      } catch (error) {
        console.error("Error fetching token purchase preferences:", error);
      } finally {
        this.loadingTokenPurchasePreference = false;
      }
    },
    async saveTokenPurchasePreference() {
      try {
        this.loading = true;
        const response = await instance.post('vouchers/purchase-preference/', this.tokenPurchaseOptions);
        console.log("Preferences saved successfully:", response.data);
      } catch (error) {
        console.error("Error saving token purchase preferences:", error);
      } finally {
        this.loading = false;
        this.editMode = false;
        this.updatedisplay_durations();
      }
    },
    updatedisplay_durations() {
      this.tokenPurchaseOptions.forEach(item => {
        item.display_duration = this.formatDuration(item.duration_in_minutes, item.price);
      });
    },
    formatDuration(durationInMinutes: number, price: string): string {
      // Logic to format duration into human-readable format
      const days = Math.floor(durationInMinutes / (24 * 60));
      const hours = Math.floor((durationInMinutes % (24 * 60)) / 60);
      const minutes = durationInMinutes % 60;

      let formattedDuration = '';

      // Build the formatted duration string
      if (days > 0) {
        formattedDuration += `${days} day${days > 1 ? 's' : ''}`;
      }

      if (hours > 0) {
        if (formattedDuration) formattedDuration += ' and ';
        formattedDuration += `${hours} hour${hours > 1 ? 's' : ''}`;
      }

      if (minutes > 0 || !formattedDuration) {
        if (formattedDuration) formattedDuration += ' and ';
        formattedDuration += `${minutes} minute${minutes > 1 ? 's' : ''}`;
      }

      // Append daily free message if price is '0 RWF'
      if (price === '0 RWF') {
        formattedDuration += ' (daily free)';
      }

      return formattedDuration;
    },
    displaySMSPaymentModal(smsPackage: SMSPackage) {
      this.selectedSMSPackage = smsPackage;

      const eulaAccepted = localStorage.getItem('EULA_ACCEPTED');

      if (!this.loggedInUser.sme.has_accepted_addons_eula && eulaAccepted !== 'Yes') {
        this.addOnToActivateAfterEula = 'sms';
        return this.showEulaModal = true;
      }

      this.showSMSPaymentModal = true;
    },
    activateSMSPackage() {
      const payload = {
        sme: this.loggedInUser.sme.id,
        addon: this.selectedSMSPackage.name,
        billing: 0
      }

      if (this.loggedInUser.sme.is_in_trial_period) {
        payload.billing = this.getLatestUnpaidBilling().id
      } else {
        payload.billing = this.getLatestPaidBilling().id
      }

      this.processingAddOnActivation = true;

      instance
        .post('sme/activate-addon', payload)
        .then((res: any) => {
          this.processingAddOnActivation = false;
          this.$toast.success(res.data.message, {
            position: 'top-right',
            duration: 8000
          })

          localStorage.removeItem('EULA_ACCEPTED');

          setTimeout(() => window.location.reload(), 2000);
        })
        .catch(() => {
          this.processingAddOnActivation = false;
        });
    },
    async acceptEula() {
      try {
        this.processingEuala = true;

        const payload = {
          has_accepted_addons_eula: true
        }

        await instance.patch(`smes/${this.loggedInUser.sme.id}/`, payload);

        this.processingEuala = false;
        this.showEulaModal = false;

        localStorage.setItem('EULA_ACCEPTED', 'Yes');

        if (this.addOnToActivateAfterEula === 'sms') {
          this.displaySMSPaymentModal(this.selectedSMSPackage);
        } else if (this.addOnToActivateAfterEula === 'food') {
          await this.activateFoodMenuAddOn();
        } else if (this.addOnToActivateAfterEula === 'feedback') {
          await this.activateFeedbackAddOn();
        } else if (this.addOnToActivateAfterEula === 'auto-connect') {
          await this.activateAutoConnectAddOn();
        } else {
          await this.activateTableAddOn();
        }
      } catch (error) {
        this.processingEuala = false;
        this.$toast.error("Could not process the acceptance. Try again", {
          position: 'top-right',
        });
      }
    }
    ,
    requestForAddOnDeactivation() {
      this.deactivating = true;
      this.loadingCancellation = true;

      let payload = {
        billing: null,
        sme: this.loggedInUser.sme.id,
        addon: ''
      }

      if (this.loggedInUser.sme.is_in_trial_period) {
        payload.billing = this.getLatestUnpaidBilling().id
      } else {
        payload.billing = this.getLatestPaidBilling().id
      }

      if (this.addOnToDeactivate === 'sms') {
        payload.addon = this.loggedInUser.sme.sms_package?.name;
      } else {
        payload.addon = this.addOnToDeactivate;
      }

      instance
        .post('sme/deactivate-addon', payload)
        .then(() => {
          this.deactivating = false;
          this.loadingCancellation = false;
          this.$toast.success('Add On deactivated on the next billing successfully', {
            position: 'top-right',
            duration: 6000
          });
          this.toggleCancellationDialog(false);
          setTimeout(() => window.location.reload(), 8000);
        })
        .catch((error: any) => {
          this.deactivating = false;
          this.loadingCancellation = false;
          if (error.response) {
            this.$toast.error(error.response.data.message, {
              position: 'top-right',
            });
          }
        });
    },
    changeSMSPackage(packageID: number) {
      if (!this.subscriptionBillings.length) return;

      this.processingSMSPackage = true;
      const payload = {
        sme: this.loggedInUser.sme.id,
        sms_package: packageID,
        sms_billing: 0
      };

      payload.sms_billing = this.getLatestUnpaidSMSBilling().id

      instance
        .post('partner/sms-package/change/', payload)
        .then(() => {
          this.$toast.success('SMS package change request successful', {
            position: 'top-right',
          });
          this.processingSMSPackage = false;
          this.fetchPendingSMSPackages();
        })
        .catch(() => {
          this.processingSMSPackage = false;
          this.$toast.error('SMS package change request failed', {
            position: 'top-right',
          });
        });
    },
    fetchPendingSMSPackages() {
      instance
        .get(`partner/sms-package/change/?sme=${this.loggedInUser.sme.id}`)
        .then((response: any) => {
          console.log('PENDING SUBSCRIPTIONS CHANGE: ', response.data);
          this.pendingSMSPackages = response.data;
        })
        .catch((error: any) => {
          console.error(error);
        });
    },
    fetchFoodDrinkMenus() {
      instance
        .get(`food-drink-menu/?realm__sme=${this.loggedInUser.sme.id}`)
        .then((response: { data: any }) => {
          this.foodDrinkMenus = response.data;
          console.log('FOOD AND DRINKS MENUS FETCHED: ', this.foodDrinkMenus);
        })
        .catch((error: any) => {
          console.log(error);
        });
    },
    downloadFile(file: string) {
      window.open(file, '_blank', 'noreferrer');
    },
    async deleteMenu(fileID: number) {
      try {
        this.currentDeletionFileID = fileID;
        this.deleting = true;
        await instance.delete(`food-drink-menu/${fileID}`).then(() => {
          this.$toast.success('The menu has been deleted successfully', {
            position: 'top-right',
          });
          this.deleting = false;
          this.currentDeletionFileID = 0;
          this.foodDrinkMenus = this.foodDrinkMenus.filter(
            (menu) => menu.id !== fileID
          );
        });
      } catch (e: any) {
        this.deleting = false;
        this.currentDeletionFileID = 0;
        if (e.response.data.detail) {
          this.$toast.error(e.response.data.detail, {
            position: 'top-right',
          });
        } else {
          this.$toast.error('Could not delete the menu', {
            position: 'top-right',
          });
        }
      }
    },
    async activateFoodMenuAddOn() {
      const eulaAccepted = localStorage.getItem('EULA_ACCEPTED');

      if (!this.loggedInUser.sme.has_accepted_addons_eula && eulaAccepted !== 'Yes') {
        this.addOnToActivateAfterEula = 'food';
        return this.showEulaModal = true;
      }

      const payload = {
        sme: this.loggedInUser.sme.id,
        addon: 'menu',
        billing: 0
      }

      if (this.loggedInUser.sme.is_in_trial_period) {
        payload.billing = this.getLatestUnpaidBilling().id
      } else {
        payload.billing = this.getLatestPaidBilling().id
      }

      this.processingAddOnActivation = true;

      try {
        const res = await instance.post('sme/activate-addon', payload);
        this.processingAddOnActivation = false;
        this.$toast.success(res.data.message, {
          position: 'top-right',
          duration: 8000
        });

        localStorage.removeItem('EULA_ACCEPTED');

        setTimeout(() => window.location.reload(), 2000);
      } catch (error) {
        this.processingAddOnActivation = false;
        console.error(error);
      }
    }
    ,
    async activateFeedbackAddOn() {
      const eulaAccepted = localStorage.getItem('EULA_ACCEPTED');

      if (!this.loggedInUser.sme.has_accepted_addons_eula && eulaAccepted !== 'Yes') {
        this.addOnToActivateAfterEula = 'feedback';
        return this.showEulaModal = true;
      }

      const payload = {
        sme: this.loggedInUser.sme.id,
        addon: 'feedback',
        billing: 0
      }

      if (this.loggedInUser.sme.is_in_trial_period) {
        payload.billing = this.getLatestUnpaidBilling().id
      } else {
        payload.billing = this.getLatestPaidBilling().id
      }

      this.processingAddOnActivation = true;

      instance
        .post('sme/activate-addon', payload)
        .then((res: any) => {
          this.processingAddOnActivation = false;
          this.$toast.success(res.data.message, {
            position: 'top-right',
            duration: 8000
          })

          localStorage.removeItem('EULA_ACCEPTED');

          setTimeout(() => window.location.reload(), 2000);
        })
        .catch(() => {
          this.processingAddOnActivation = false;
        });
    },
    async activateTableAddOn() {
      const eulaAccepted = localStorage.getItem('EULA_ACCEPTED');

      if (!this.loggedInUser.sme.has_accepted_addons_eula && eulaAccepted !== 'Yes') {
        this.addOnToActivateAfterEula = 'table';
        return this.showEulaModal = true;
      }

      const payload = {
        sme: this.loggedInUser.sme.id,
        addon: 'table',
        billing: 0
      }

      if (this.loggedInUser.sme.is_in_trial_period) {
        payload.billing = this.getLatestUnpaidBilling().id
      } else {
        payload.billing = this.getLatestPaidBilling().id
      }

      this.processingAddOnActivation = true;

      instance
        .post('sme/activate-addon', payload)
        .then((res: any) => {
          this.processingAddOnActivation = false;
          this.$toast.success(res.data.message, {
            position: 'top-right',
            duration: 8000
          });

          localStorage.removeItem('EULA_ACCEPTED');

          setTimeout(() => window.location.reload(), 2000);
        })
        .catch(() => {
          this.processingAddOnActivation = false;
        });
    },
    async activateAutoConnectAddOn() {
      const eulaAccepted = localStorage.getItem('EULA_ACCEPTED');

      if (!this.loggedInUser.sme.has_accepted_addons_eula && eulaAccepted !== 'Yes') {
        this.addOnToActivateAfterEula = 'auto-connect';
        return this.showEulaModal = true;
      }

      this.processingAddOnActivation = true;
      instance
        .patch(`smes/${this.loggedInUser.sme.id}/`, {
          has_autoconnect_addon_enabled: true,
        })
        .then(() => {
          this.processingAddOnActivation = false;
          this.$toast.success('Auto-Connect Add-On activated successfully', {
            position: 'top-right',
          });

          localStorage.removeItem('EULA_ACCEPTED');

          setTimeout(() => window.location.reload(), 2000);
        })
        .catch(() => {
          this.processingAddOnActivation = false;
        });
    },
    activateTokenPurchaseAddOn() {
      this.processingAddOnActivation = true;
      instance
        .patch(`smes/${this.loggedInUser.sme.id}/`, {
          has_token_purchase_addon_enabled: true,
        })
        .then(() => {
          this.processingAddOnActivation = false;
          this.$toast.success('Token Purchase Add-On activated successfully', {
            position: 'top-right',
          });

          setTimeout(() => window.location.reload(), 2000);
        })
        .catch(() => {
          this.processingAddOnActivation = false;
        });
    },
    closeModal() {
      this.showUploadMenuModal = false;
      this.fetchFoodDrinkMenus();
    },
    handleChange() {
      this.perSize = this.selectedOption;
    },
    fetchFeedback(data?: any) {
      if (!data) return;

      this.loading = true;
      instance
        .get(
          `rating/?sme_id=${this.loggedInUser.sme.id}&start_date=${data.startDate}&end_date=${data.endDate}`
        )
        .then((response) => {
          this.feedbackList = response.data;
          this.propLoaded = true;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    filteredTableData(): any[] {
      if (!this.searchQuery) {
        // If search query is empty, return all data
        return this.feedbackList;
      }

      // Filter table data based on search query
      const filteredData = this.feedbackList.filter((item) => {
        // Convert item values to lowercase for case-insensitive search
        const rating = item.rating.toString().toLowerCase();
        const comment = item.comment.toString().toLowerCase();
        const createdAt = item.created.toString().toLowerCase();
        const query = this.searchQuery.toLowerCase();

        // Check if name or age contain the search query
        return (
          rating.includes(query) ||
          comment.includes(query) ||
          createdAt.includes(query)
        );
      });
      return filteredData;
    },
    filterTableData(): Feedback[] {
      if (!this.searchQuery) {
        // If search query is empty, return all data
        return this.feedbackList;
      }

      // Filter table data based on search query
      const filteredData = this.feedbackList.filter((item) => {
        // Convert item values to lowercase for case-insensitive search
        const rating = item.rating.toString().toLowerCase();
        const comment = item.comment.toString().toLowerCase();
        const createdAt = item.created.toString().toLowerCase();
        const query = this.searchQuery.toLowerCase();

        // Check if name or age contain the search query
        return (
          rating.includes(query) ||
          comment.includes(query) ||
          createdAt.includes(query)
        );
      });
      return filteredData;
    },
    deactivateAddOn(addon: string) {
      const dialogAddons = ['sms-addon', 'menu-addon', 'feedback-addon', 'table-reservation-addon'] as const;
      const payloadAddons = ['autoconnect-addon', 'token-purchase-addon'] as const;

      if (dialogAddons.includes(addon as typeof dialogAddons[number])) {
        this.addOnToDeactivate = this.getAddonKey(addon);
        this.toggleCancellationDialog(true);
        return;
      }

      if (payloadAddons.includes(addon as typeof payloadAddons[number])) {
        const payload = this.getPayloadForAddon(addon);
        this.deactivateAddonRequest(payload);
      }
    },
    getAddonKey(addon: string): string {
      const addonKeyMap: { [key: string]: string } = {
        'sms-addon': 'sms',
        'menu-addon': 'menu',
        'feedback-addon': 'feedback',
        'table-reservation-addon': 'table'
      };
      return addonKeyMap[addon] || '';
    },
    getPayloadForAddon(addon: string): object {
      const payloadMap: { [key: string]: object } = {
        'autoconnect-addon': { has_autoconnect_addon_enabled: false },
        'token-purchase-addon': { has_token_purchase_addon_enabled: false }
      };
      return payloadMap[addon] || {};
    },
    deactivateAddonRequest(payload: object) {
      instance
        .patch(`/smes/${this.loggedInUser.sme.id}/`, payload)
        .then(() => {
          this.deactivating = false;
          this.showSuccessToast('Add-On deactivated successfully');
          this.reloadPageAfterDelay();
        })
        .catch(() => {
          this.deactivating = false;
          this.showErrorToast('Could not deactivate the Add-On');
        });
    },
    showSuccessToast(message: string) {
      this.$toast.success(message, { position: 'top-right' });
    },
    showErrorToast(message: string) {
      this.$toast.error(message, { position: 'top-right' });
    },
    reloadPageAfterDelay() {
      setTimeout(() => window.location.reload(), 2000);
    },
    getVoucherPreference() {
      instance
        .get(`vouchers/automation-preference/?sme=${this.loggedInUser.sme.id}`)
        .then((response: { data: any }) => {
          if (response.data.duration) {
            this.autoconnectDailyUsage = response.data.autoconnect_daily_usage;
            this.duration = response.data.duration;
            response.data.custom_duration !== 0
              ? (this.customDuration = response.data.custom_duration)
              : null;
          }
        })
        .catch((error: any) => {
          if (error.response.status === 404) {
            console.log('VOUCHER PREFERENCE NOT FOUND: ');
          }
        });
    },
    setVoucherPreference() {
      if (
        this.activeTab === 'sms-package' && this.loggedInUser.sme.voucher_sms_feature_enabled &&
        this.duration === 'Select Default Duration'
      ) {
        return this.$toast.warning('Select the default duration', {
          position: 'top-right',
        });
      }

      if (
        this.activeTab === 'autoconnect' && this.loggedInUser.sme.has_autoconnect_addon_enabled &&
        this.duration === 'Select Default Duration'
      ) {
        return this.$toast.warning('Select the default duration', {
          position: 'top-right',
        });
      }

      if (this.duration === 'custom' && !this.customDuration) {
        return this.$toast.warning('Define the custom duration in minutes', {
          position: 'top-right',
        });
      }

      this.loadingVoucherDurationPreference = true;

      const payload = {
        sme_id: this.loggedInUser.sme.id,
        default_duration:
          this.duration === 'Select Default Duration' ? null : this.duration,
        custom_duration: this.customDuration !== 0 ? this.customDuration : null,
        autoconnect_daily_usage: this.autoconnectDailyUsage,
      };

      instance
        .post('vouchers/automation-preference/', payload)
        .then(() => {
          this.loadingVoucherDurationPreference = false;
          this.$toast.success('Token duration preference updated', {
            position: 'top-right',
          });
        })
        .catch(() => {
          this.loadingVoucherDurationPreference = false;
          this.$toast.warning(
            'Could not update the token duration preference',
            {
              position: 'top-right',
            }
          );
        });
    },
    setTokenPurchasePreference() {
      if (
        this.loggedInUser.sme.voucher_sms_feature_enabled &&
        this.duration === 'Select Default Duration'
      ) {
        return this.$toast.warning('Select the default duration', {
          position: 'top-right',
        });
      }

      if (this.duration === 'custom' && !this.customDuration) {
        return this.$toast.warning('Define the custom duration in minutes', {
          position: 'top-right',
        });
      }

      this.loadingVoucherDurationPreference = true;

      const payload = {
        partner_id: this.loggedInUser.sme.id,
        default_duration:
          this.duration === 'Select Default Duration' ? null : this.duration,
        custom_duration: this.customDuration !== 0 ? this.customDuration : null,
      };

      instance
        .post('vouchers/automation-preferenced/', payload)
        .then(() => {
          this.loadingVoucherDurationPreference = false;
          this.$toast.success('Token duration preference updated', {
            position: 'top-right',
          });
        })
        .catch(() => {
          this.loadingVoucherDurationPreference = false;
          this.$toast.warning(
            'Could not update the token duration preference',
            {
              position: 'top-right',
            }
          );
        });
    },
    fetchSubscriptionBillings() {
      instance
        .get(`partner/billing?sme=${this.loggedInUser.sme.id}&period=future`)
        .then(({ data }: { data: Billing[] }) => {
          this.subscriptionBillings = data;
        })
        .catch((error: any) => {
          console.error(error);
        });
    },
    getLatestPaidBilling() {
      const paidBillings = this.loggedInUser.sme.billings.filter((billing: Billing) => billing.is_paid);

      if (!paidBillings.length) {
        return null;
      }

      // Find the billing with the latest created date among filtered ones
      const latestBilling = paidBillings.reduce((latest: Billing, current: Billing) => {
        const latestCreatedDate = new Date(latest.created);
        const currentCreatedDate = new Date(current.created);
        return currentCreatedDate > latestCreatedDate ? current : latest;
      });

      return latestBilling;
    },
    getLatestUnpaidBilling() {
      const unpaidBillings = this.loggedInUser.sme.billings.filter((billing: Billing) => !billing.is_paid);

      if (!unpaidBillings.length) {
        return null;
      }

      // Find the billing with the latest created date among filtered ones
      const latestBilling = unpaidBillings.reduce((latest: Billing, current: Billing) => {
        const latestCreatedDate = new Date(latest.created);
        const currentCreatedDate = new Date(current.created);
        return currentCreatedDate > latestCreatedDate ? current : latest;
      });

      return latestBilling;
    },
    getLatestUnpaidSMSBilling() {
      const unpaidBillings = this.loggedInUser.sme.sms_package_billings.filter((billing: SmsPackageBilling) => !billing.is_paid);

      if (!unpaidBillings.length) {
        return null;
      }

      // Find the billing with the latest created date among filtered ones
      const latestBilling = unpaidBillings.reduce((latest: Billing, current: Billing) => {
        const latestCreatedDate = new Date(latest.created);
        const currentCreatedDate = new Date(current.created);
        return currentCreatedDate > latestCreatedDate ? current : latest;
      });

      return latestBilling;
    },
    isPendingSMSAddOnCancellation() {
      const { sme } = this.loggedInUser;

      if (!sme || !sme.sms_package || !sme.addon_cancellations) {
        return false;
      }

      const { sms_package, addon_cancellations } = sme;
      const currentDate = new Date();

      const pendingCancellation = addon_cancellations.find((addOnCancellation: AddOnCancellation) => {
        const cancellationDate = new Date(addOnCancellation.cancellation_date);
        return !addOnCancellation.is_processed &&
          addOnCancellation.addon.name === sms_package.name &&
          cancellationDate > currentDate;
      });

      return !!pendingCancellation;
    },
    isPendingAddOnCancellation(addon: string) {
      const { sme } = this.loggedInUser;


      if (!sme || !sme.addon_cancellations) {
        return false;
      }

      const { addon_cancellations } = sme;
      const currentDate = new Date();

      const pendingCancellation = addon_cancellations.find((addOnCancellation: AddOnCancellation) => {
        const cancellationDate = new Date(addOnCancellation.cancellation_date);
        return !addOnCancellation.is_processed &&
          addOnCancellation.addon.name === addon &&
          cancellationDate > currentDate;
      });

      console.log(`IS PENDING ${addon.toUpperCase()} CANCELLATION: `, !!pendingCancellation);

      return !!pendingCancellation;
    }

  },
  created() {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get('tab');

    if (tab) {
      this.activeTab = tab;
    }

    this.getVoucherPreference();
    this.fetchSMSPackages();
    this.fetchFoodDrinkMenus();
    this.fetchFeedback();
    this.fetchSubscriptionBillings();
    this.isPendingAddOnCancellation('menu');
    this.fetchTokenPurchasePreferences();
  },
});
</script>

<style>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-btn:hover+.tooltip-text {
  visibility: visible;
  opacity: 1;
}

.dotonline {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}

.dotoffline {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}
</style>